@import '../bootstrapTheme.scss';

.Container {

  @media only screen and (max-width: map-get($container-max-widths, 'md')) {
    padding-bottom: $spacer;
  }
}

.Content {
  border-top: 1px solid transparent;
  transition: border-top-color 0.3s ease-in-out;
}
.Scrolled {
  border-top-color: #dfdfdf;
}
